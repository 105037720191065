import React from 'react'

function Icon({ size = '24' }: { size?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 32 32">
      <path
        fill="#1E1E1E"
        d="M26.8 12.293c-2.413 0-3.4-1.706-2.2-3.8a2.529 2.529 0 00-.934-3.453L21.36 3.72c-1.053-.627-2.413-.253-3.04.8l-.147.253c-1.2 2.094-3.173 2.094-4.386 0l-.147-.253a2.188 2.188 0 00-3.014-.8L8.32 5.04c-1.214.693-1.627 2.253-.934 3.467 1.214 2.08.227 3.786-2.186 3.786a2.54 2.54 0 00-2.534 2.534v2.346A2.54 2.54 0 005.2 19.707c2.413 0 3.4 1.706 2.186 3.8a2.529 2.529 0 00.934 3.453l2.306 1.32c1.054.627 2.414.253 3.04-.8l.147-.253c1.2-2.094 3.173-2.094 4.387 0l.146.253c.627 1.053 1.987 1.427 3.04.8l2.307-1.32a2.532 2.532 0 00.933-3.453c-1.213-2.094-.226-3.8 2.187-3.8a2.54 2.54 0 002.534-2.534v-2.346c-.014-1.387-1.147-2.534-2.547-2.534zM16 20.333A4.342 4.342 0 0111.666 16 4.342 4.342 0 0116 11.667 4.342 4.342 0 0120.333 16 4.342 4.342 0 0116 20.333z"
      ></path>
    </svg>
  )
}

export default Icon
